import { Link } from "react-router-dom";
import '../styles/contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Footer from "../components/Footer.jsx";


function Contact(){

    return (
      <div className="contact_page">
      <div className="nav_space">
          <div className="about_title contact">Contact</div>
          <div className="nav_about">
              <Link to="/" className="nav contact">Home</Link>
              <Link to="/AboutMe" className="nav contact">Skills</Link>
              <Link to="/" className="nav contact">Projects</Link>
          </div>
      </div>


      <div className="ctc_place">
        <div className="ctc left">
          <div className="icon_place">
            <FontAwesomeIcon icon={faPhone} className="icon_phone"/>
          </div>
          <div className="contact_txt phone">+33 (0)783538056</div>

          <div className="icon_place">
            <FontAwesomeIcon icon={faEnvelope} className="icon_phone" />
          </div>
          <a href="mailto:fleury.johanna64@gmail.com" className="contact_txt">fleury.johanna64@gmail.com</a>

          <div className="icon_place">
            <FontAwesomeIcon icon={faLocationDot} className="icon_phone"/>
          </div>
          <div className="contact_txt">Toulouse, 31200 </div>

          <div className="icon_place">
            <FontAwesomeIcon icon={faLinkedin} className="icon_phone"/>
          </div>
          <a href="https://www.linkedin.com/in/johanna-fleury-dev6485/" target="_blank" className="contact_txt">Johanna-Fleury
            </a>
        </div>
        <div className="ctc right">Contact Me
        </div>
        
      </div>
      <Footer/>
  </div>
  
      );
    }      

export default Contact